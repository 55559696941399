import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const Title = styled.h1`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
`

const Hero = props => (
  <Wrapper className="post-image-wrapper" height={props.height}>
    <Img className="post-image" fluid={props.image.fluid} backgroundColor={'#eeeeee'} />
    <Title className="post-title">
      {props.title}
      <span className="post-date">{props.date}, di {props.author}</span>
      <span className="post-time-to-read">- {props.timeToRead}' di lettura -</span>
    </Title>
  </Wrapper>
)

export default Hero
