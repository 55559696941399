import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import LBNavigation from '../components/LBNavigation'
import Hero from '../components/Hero'
import Container from '../components/Container'
import SEO from '../components/SEO'
import LBCondividi from '../components/LBCondividi';
import { useSiteMetadata } from '../hooks/use-site-metadata'

const PostTemplate = ({ data, pageContext }) => {
  useEffect(() => {
    document.body.classList.add("overflow-auto");
    // will unmount
    return () => {
      document.body.classList.remove("overflow-auto");
    }
  }, [])

  const {
    title,
    slug,
    metaDescription,
    heroImage,
    body,
    publishDate,
    tags,
  } = data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext
  const { siteUrl } = useSiteMetadata()
  const articleUrlEncoded = encodeURIComponent(siteUrl+"/"+slug)
  const articleTitleEncoded = encodeURIComponent(title)

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <div className="post-container">
      <LBNavigation isHome={false}/>
      <Layout pageClass="post-page">
        <SEO
          title={title}
          description={
            metaDescription
              ? metaDescription.internal.content
              : body.childMarkdownRemark.excerpt
          }
          image={ogImage}
        />
        <Hero title={title} image={heroImage} author="Luca Belli" timeToRead={body.childMarkdownRemark.timeToRead} date={publishDate} height={'50vh'} />
        <Container>
          <div className="post-category">
          {tags.map((tag, index) => (
            <span key={tag.slug}>
            {(index ? '' : '')} 
            <Link to={`/tag/${tag.slug}/`} className='lb-tag'>{tag.title}</Link>
            </span>
          ))}
          </div>
          <div className="post-body text-styles" dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}></div>
          <div className="post-signature"><img src="/images/logo-blue.svg" width="50" height="50"/></div>
        </Container>
        <LBCondividi articleUrlEncoded={articleUrlEncoded} articleTitleEncoded={articleTitleEncoded} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "DD MMMM YYYY", locale: "it")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
